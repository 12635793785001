import eventsHandler from '@/services/common/eventsHandler.js'

class hairTypeForm extends eventsHandler {
  constructor() {
    super()
    this._hairType = {}
    this._valid = false
  }

  get hairType() {
    const hairType = {
      ...this._hairType
    }
    return hairType
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._hairType.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetHairType() {
    this._hairType = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {hairType: {}})
  }

  updateField(key, value) {
    this._hairType[key] = value
    this._executeCallbacksOf('update', {hairType: this._hairType})
    this._executeCallbacksOf('update-' + key, {key: this._hairType[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(hairType) {
    this._hairType = hairType
    this._executeCallbacksOf('update', {hairType: this._hairType})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new hairTypeForm()